

























































































































































































































































































































import { apiGetCustomerList } from "@/api/shop";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";
import { electronicVoucherOrderList } from "@/api/electronicVoucher";
import { apiProductFileLists } from "@/api/productFile";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class electronCard extends Vue {
    electronicVoucherOrderList = electronicVoucherOrderList;
    @Prop() semiSeq!: string;

    pager: RequestPaging = new RequestPaging();
    searchObj: any = {
        order_sn: "",
        batch_id: "",
        semiSeq: "",
        company_name: "",
        create_time: "",
        type: "",
        custom_id: "",
        status: "",
    };
    productFileLists: any = [];
    custom_list: any = [];

    tableData: any[] = [];
    async getCustomerList() {
        const custom_list_array = await apiGetCustomerList({
            page_type: 0,
            type: 0,
        });
        this.custom_list = custom_list_array.lists;
    }
    async getProductFileLists() {
        const product_file_list_array = await apiProductFileLists({
            page_type: 0,
        });
        this.productFileLists = product_file_list_array.lists;
        console.log(this.productFileLists, "this.productFileLists");
    }
    created() {
        this.getCardList(1);
        this.getCustomerList();
        this.getProductFileLists();
    }

    // 列表
    getCardList(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: electronicVoucherOrderList,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getCardList(1);
    }

    // 去编辑商城
    toCardDetail(id: number | any): void {
        this.$router.push({
            path: "/card/makeCard/electronCardDetail",
            query: {
                id: id,
            },
        });
    }
    // 去编辑商城
    toCardEdit(id: number | any): void {
        this.$router.push({
            path: "/card/makeCard/electronCardEdit",
            query: {
                id: id,
            },
        });
    }
}
